.el-scrollbar[data-v-6146b30c] {
  height: 100%;
}
.box .el-scrollbar__wrap[data-v-6146b30c] {
  overflow: scroll;
}
[data-v-6146b30c] .UserDeptTree .el-tree {
  height: 60vh !important;
  max-height: 60vh !important;
}
[data-v-6146b30c] .UserDeptCrud .avue-crud .el-table {
  height: 55vh !important;
  max-height: 55vh !important;
}
[data-v-6146b30c] .el-dialog {
  margin-bottom: 0px !important;
}